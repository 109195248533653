<template>
  <!-- 预约退款记录 -->
  <div class="page1">
    <div class="content">
      <!-- 搜索条件区域 -->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="手机号:">
                <el-input v-model="formInline.phone" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="停车场名称:">
                <!-- <el-input v-model="formInline.parkingName" placeholder="请输入停车场名称"></el-input> -->
                <el-autocomplete
                  v-model="state"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  @select="handleSelect"
                  value-key="parkName"
                  :trigger-on-focus="false"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="预约到场时间:">
                <el-date-picker
                  v-model="searchDateS"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <!-- <el-form-item label="车牌号:" label-width="60px">
            <el-input v-model="formInline.plateNumber" placeholder="请输入车牌号"></el-input>
          </el-form-item>-->

              <el-form-item label="退款方式:">
                <el-select v-model.trim="formInline.refundStatus" filterable>
                  <el-option
                    :label="item.channelTypeDesc"
                    :value="item.channelType"
                    :key="item.channelType"
                    v-for="item in payTypeList"
                  ></el-option>
                  <!-- <el-option label="全部状态" value></el-option>
                <el-option label="余额支付" value="1"></el-option>
                <el-option label="微信公众号支付" value="3"></el-option>
                <el-option label="微信app支付" value="4"></el-option>
                <el-option label="支付宝app支付" value="5"></el-option>
                <el-option label="微信小程序" value="52"></el-option> -->
                </el-select>
              </el-form-item>
              <el-form-item label="退款时间:">
                <el-date-picker
                  v-model="searchDateE"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
              <el-form-item label="第三方流水号:" label-width="110px">
                <el-input
                  v-model="formInline.refundOutTradeCode"
                  placeholder="请输入第三方流水号"
                ></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchData(1)"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          border
          header-cell-class-name="header-call-style"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            :index="indexMethod"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column prop="refundOutTradeNo" :label="$t('searchModule.Third_party_serial_number')" align="center">
            <template slot-scope="scope" v-if="scope.row.refundOutTradeCode">
              <el-tooltip
                effect="dark"
                :content="scope.row.refundOutTradeCode"
                placement="top-start"
              >
                <span v-clipboard:copy="scope.row.refundOutTradeCode" v-clipboard:success="onCopy">
                  <el-button type="text" size="small">{{
                    scope.row.refundOutTradeCode
                      ? scope.row.refundOutTradeCode.slice(0, 5) + "..."
                      : ""
                  }}</el-button>
                </span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper" v-show="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/common/js/public.js";
export default {
  name: "reservationRefund",
  data() {
    return {
      state: "",
      total: 0,
      pageSize: 15,
      page: 1,
      index: 0,
      loading: false,
      searchDateS: "",
      searchDateE: "",
      formInline: {
        parkingName: "",
        refundStatus: "",
        phone: "",
        parkId: "",
        // plateNumber: "",
        // waterCode: "",
        refundOutTradeCode: "",
      },
      payTypeList: [],
      tableData: [],
      tableCols: [
        {
          prop: "plateColor",
          label: this.$t("list.plate_number"),
          formatter: (row) => {
            // return row.plateColor + "：" + row.plateNumber;
            return row.plateNumber && row.plateNumber.indexOf("无") != -1
              ? "无牌车"
              : row.plateColor + "：" + row.plateNumber;
          },
        },
        {
          prop: "phoneNumber",
          label: this.$t("list.phone_number"),
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
        },
        {
          prop: "appointmentStartTime",
          label: "预约到场时间",
        },
        {
          prop: "appointmentMoney",
          label: this.$t("list.Pay_amount"),
          formatter: (row) => {
            return row.appointmentMoney / 100;
          },
        },
        {
          prop: "refundMoney",
          label: this.$t("list.refund_amount"),
          formatter: (row) => {
            return row.refundMoney / 100;
          },
        },
        {
          prop: "refundTypeName",
          label: this.$t("list.Refund_method"),
        },
        // {
        //   prop: "refundType",
        //   label: this.$t("list.Refund_method"),
        //   formatter: (row) => {
        //     if (row.refundType == 1) {
        //       return "余额支付";
        //     } else if (row.refundType == 3) {
        //       return "微信公众号支付";
        //     } else if (row.refundType == 4) {
        //       return "微信app支付";
        //     } else if (row.refundType == 5) {
        //       return "支付宝app支付";
        //     } else if (row.refundType == 52) {
        //       return "微信小程序";
        //     } else {
        //       return "";
        //     }
        //   },
        // },
        {
          prop: "refundTime",
          label: this.$t("list.Refund_time"),
        },
        // {
        //   prop: "waterCode",
        //   label: "第三方流水号"
        // }
      ],
    };
  },
  created() {
    this.getInItSeclete();
    this.searchData(1);
  },
  watch: {},
  methods: {
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      this.$axios
        .get("/acb/2.0/systems/loginUser/parkNameList", {
          data: {
            parkTypes: "3,4",
            slaveRelations: "0,2",
            parkName: queryString,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.parkId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    handleSelect(item) {
      this.formInline.parkId = item.parkId;
      // console.log(item);
    },
    getInItSeclete() {
      /* this.$axios.get("/acb/2.0/parkAppointmentRefundRecord/spinner").then((res) => {
        if (res.state == 0) {
          // console.log("res.value.payType==>", res.value.payType);
          // let allType = { code: "", desc: "全部" };
          let allArr = [{ code: "", desc: "全部" }];
          let a = allArr.concat(res.value.payType);
          this.payTypeList = a;
          console.log(this.payTypeList);
        }
      }); */
      let opt = {
        method: "get",
        url: "/acb/2.0/payOrder/payChannelTypeDictList",
        success: (res) => {
          if (res.state == 0) {
            this.payTypeList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    searchData(tag) {
      if (tag == 1) {
        this.page = 1;
      }

      // console.log(
      //   "$route.meta.authority.button",
      //   this.$route.meta.authority.button
      // );

      if (this.state.length < 1) {
        this.formInline.parkId = "";
        this.state = "";
      }
      this.tableData = [];
      this.loading = true;
      // console.log(this.formInline);
      // console.log(dateFormat(this.searchDateS[0], "yyyy-MM-dd"));
      // console.log(dateFormat(this.searchDateS[1], "yyyy-MM-dd"));
      // console.log(this.searchDateE);

      this.$axios
        .get("/acb/2.0/parkAppointmentRefundRecord/list", {
          data: {
            parkId: this.formInline.parkId,
            refundStartTime:
              this.searchDateE == null || this.searchDateE == ""
                ? ""
                : dateFormat(this.searchDateE[0], "yyyy-MM-dd") + " 00:00:00",
            refundEndTime:
              this.searchDateE == null || this.searchDateE == ""
                ? ""
                : dateFormat(this.searchDateE[1], "yyyy-MM-dd") + " 23:59:59",
            appointmentStartTime:
              this.searchDateS == null || this.searchDateS == ""
                ? ""
                : dateFormat(this.searchDateS[0], "yyyy-MM-dd") + " 00:00:00",
            appointmentEndTime:
              this.searchDateS == null || this.searchDateS == ""
                ? ""
                : dateFormat(this.searchDateS[1], "yyyy-MM-dd") + " 23:59:59",
            refundType: this.formInline.refundStatus,
            phoneNumber: this.formInline.phone,
            refundOutTradeCode: this.formInline.refundOutTradeCode,
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then(
          (res) => {
            if (res.state == 0) {
              this.tableData = res.value.list;

              this.total = res.value.total * 1;
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          },
          (fail) => {
            this.total = 0;
          }
        );
    },
    indexMethod(index) {
      return index + 1;
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
  },
  components: {},
  mounted() {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
